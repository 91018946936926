import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Avatar, Box, Fab, Stack, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import AppInputSelectAutocomplete from "components/Input/AppInputSelectAutocomplete";
import MainCard from "components/MainCard";
import { manageCirculation } from "redux/actions";
import Axios from "utils/axios";
import { addDays, formatName, generateProtectedPublicUrl } from "utils/utilities";
import FullScreenDialog from "./FullScreenDialog";

const AccessionIssueDialog = () => {

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const [ accessions, setAccessions ] = useState([]);
  const [ students, setStudents ] = useState([]);
  const [ showDialog, setShowDialog ] = useState(false);
  const [ queryAccession, setQueryAccession ] = useState("");
  const [ queryStudent, setQueryStudent ] = useState("");
  const [ selectedAccession, setSelectedAccession ] = useState(null);
  const [ selectedAcc, setSelectedAcc ] = useState(null);
  const [ selectedStudent, setSelectedStudent ] = useState(null);
  const [ selectedStd, setSelectedStd ] = useState(null);

  const accessionInputRef = useRef(null);
  const studentInputRef = useRef(null);

  useEffect(() => {    
    async function fetchData(){
      try{
        const { data } = await Axios.get(`/library/accession?accessionNumber=${queryAccession}&page=1&limit=25&libraryId=${user?.libraryId}&status=AVAILABLE`);      
        setAccessions(data?.data?.accessions);
        if(data?.data?.accessions?.length === 1){
          const singleAccession = data?.data?.accessions[0];
          setSelectedAccession({ value: singleAccession.id, label: `${singleAccession?.accessionNumber}-${singleAccession?.name}` });
          setSelectedAcc(singleAccession);
          studentInputRef.current.focus();
        }
      }catch(error){
        toast.error(error?.response?.data?.error);
      }
    }
    if(queryAccession) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ queryAccession, dispatch ]) 

  useEffect(() => {    
    async function fetchData(){
      try{
        const { data } = await Axios.get(`/library/student?query=${queryStudent}`);              
        setStudents(data?.data);
        if(data?.data?.length === 1){
          const singleStudent = data?.data[0];
          setSelectedStudent({ value: singleStudent?.id, label: `${singleStudent?.rollNumber} - ${formatName(singleStudent?.student?.firstName, singleStudent?.student?.middleName, singleStudent?.student?.lastName)} - ${singleStudent?.collegeCourse?.course?.name}` });
          setSelectedStd(singleStudent);
        }
      }catch(error){
        toast.error(error?.response?.data?.error);
      }      
    }
    if(queryStudent) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [ queryStudent, dispatch ])   

  useEffect(() => {
    if (accessionInputRef.current) {
      accessionInputRef.current.focus(); // Set initial focus to accession field
    }
  }, []);

  return (
    <>
      {!showDialog && (
        <Fab 
          onClick={() => setShowDialog(!showDialog)}
          color="success" 
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: 12,
            right: 12        
          }}
        >
          <AddIcon />
        </Fab>
      )}
      {showDialog && (
        <FullScreenDialog
          open={showDialog}
          setOpen={setShowDialog}
          title={`Issue Accession`}
          saveText="Issue"
          onSave={() => {
            if(selectedAcc && selectedStd){
              const form = {
                accessionId: selectedAcc?.id,
                issuedToId: selectedStd?.id,
                dueDate: addDays(new Date(), selectedAcc?.libraryCatalogue?.maxLoanDays),
              }
              dispatch(manageCirculation(null, form, "student"));            
              setShowDialog(false);
              setSelectedAccession(null);
              setSelectedAcc(null);
              setSelectedStudent(null);
              setSelectedStd(null); 
              setAccessions([]);
              setStudents([]);
              setQueryAccession("");
              setQueryStudent("");           
            }else{
              toast.error("Please select Accession and Student");
            }
          }}        
        >
          <Stack
            p={2}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            minHeight="100%"
          >
            <Box flex={1}>
              <Box mb={2}>
                <AppInputSelectAutocomplete
                  id="accession"
                  label="Accession"
                  onChange={(event, newValue) => {                    
                    setSelectedAccession(newValue);
                    setSelectedAcc(accessions?.find((acc) => acc?.id === newValue?.value));
                    if (newValue) {
                      studentInputRef.current.focus();
                    }
                  }}  
                  value={selectedAccession}
                  apiOptions={accessions?.map((accession) => ({ value: accession?.id, label: `${accession?.accessionNumber}-${accession?.name}` }))}
                  noOptionsText="No Accession Found"
                  touched={false}
                  errors={""}
                  multiple={false}
                  setQuery={setQueryAccession}                  
                  inputRef={accessionInputRef}
                  autoFocus={true}
                />              
              </Box>
              {selectedAcc && <MainCard>              
                <Box>
                  <Typography
                    variant="subtitle1"
                    color="success.main"
                  >
                    {selectedAcc?.accessionNumber}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                  >
                    {selectedAcc?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >
                    {selectedAcc?.libraryCatalogue?.name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="error.main"
                  >
                    Due Date: {addDays(new Date(), selectedAcc?.libraryCatalogue?.maxLoanDays).toLocaleDateString("sv-SE")}
                  </Typography>
                </Box>              
              </MainCard>}
            </Box>
            <Box
              flex={1}           
              sx={{              
                borderLeft: { xs: 0, sm: "1px solid #e6ebf1" },
                borderTop: { xs: "1px solid #e6ebf1", sm: 0 },              
              }}
              pl={{ sm: 2}}
              pt={{ xs: 2, sm: 0}}
            >
              <Box mb={2}>
                <AppInputSelectAutocomplete
                  id="student"
                  label="Student"
                  onChange={(event, newValue) => {                  
                    setSelectedStudent(newValue);
                    setSelectedStd(students?.find((std) => std?.id === newValue?.value));
                  }}  
                  value={selectedStudent}
                  apiOptions={students?.map((student) => ({ value: student?.id, label: `${student?.rollNumber} - ${formatName(student?.student?.firstName, student?.student?.middleName, student?.student?.lastName)} - ${student?.collegeCourse?.course?.name}`}))}
                  noOptionsText="No Student Found"
                  touched={false}
                  errors={""}
                  multiple={false}
                  setQuery={setQueryStudent}
                  inputRef={studentInputRef}
                />                
              </Box>
              {selectedStd && <MainCard>              
                <Stack
                  direction="row"
                  spacing={2}
                >
                  <Box>
                    <Avatar
                      src={selectedStd?.student?.studentDocuments[0]?.id ? generateProtectedPublicUrl(selectedStd?.student?.studentDocuments[0]?.id) : null}
                      alt={formatName(selectedStd?.courseStudent?.student?.firstName, selectedStd?.courseStudent?.student?.middleName, selectedStd?.courseStudent?.student?.lastName)}
                      sx={{
                        width: 100,
                        height: 100
                      }}
                    />
                  </Box>
                  <Box
                    flexGrow={1}
                  >
                    <Typography
                      variant="subtitle1"                  
                    >
                      {formatName(selectedStd?.student?.firstName, selectedStd?.student?.middleName, selectedStd?.student?.lastName)}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                    >
                      {selectedStd?.collegeCourse?.course?.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >
                      {selectedStd?.collegeCourse?.college?.name}
                    </Typography>
                    {selectedStd?.sessionStudents[0]?.studentAdmissionForm[0] && <LastFee
                      admissionForm={selectedStd?.sessionStudents[0]?.studentAdmissionForm[0]}
                    />}
                  </Box>              
                </Stack>
              </MainCard>}
            </Box>
          </Stack>
        </FullScreenDialog>
      )}
    </>
  )
}

export default AccessionIssueDialog

const LastFee = ({
  admissionForm
}) => {
  return(
    <Typography
      variant="caption"
      color={admissionForm?.paymentType === "FULL" ? "success.main" : "error.main"}
      fontWeight={500}
    >
      Last Fee: {admissionForm?.admissionFormCourseClass?.admissionFormCourse?.admissionForm?.name} - {admissionForm?.admissionFormCourseClass?.courseClass?.course?.name} - {admissionForm?.admissionFormCourseClass?.courseClass?.name}({admissionForm?.paymentType})
    </Typography>
  )
}